<template>
  <!-- 电脑端底部导航 -->
  <div class="left d-flex zt">
    <!-- 经典盲盒 -->
    <div
      :class="`${$route.name ==='Home'?'bottomboxs':'bottombox'}`"
      @click="$router.push({ name: 'Home' })"
    >
      <img src="@/assets/images/home/home.png" alt />
      <span>经典盲盒</span>
    </div>
    <!-- 汰换 -->
    <div :class="`${$route.name ==='replacing'?'bottomboxs':'bottombox'}`" @click="replacing">
      <img src="@/assets/images/home/replacing.png" alt />
      <span>汰换</span>
    </div>
    <!-- 大逃杀 -->
    <div class="bigKill" @click="bigKill">
      <img src="@/assets/images/home/bigkilltext.png" alt />
    </div>
    <!-- roll房 -->
    <div :class="`${$route.name ==='rollHome'?'bottomboxs':'bottombox'}`" @click="rollHome">
      <img src="@/assets/images/home/roll.png" alt />
      <span>ROLL</span>
    </div>
    <!-- pk -->
    <div disabled :class="`${$route.name ==='allpk'?'bottomboxs':'bottombox'}`" @click="allpk">
      <img src="@/assets/images/home/pk.png" alt />
      <span>竞技房</span>
    </div>
  </div>
</template>

<script>
import { getToken } from "../../../api/cookie";
export default {
  methods: {
    replacing() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "replacing" });
      }
    },
    bigKill() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "bigKill" });
      }
    },
    rollHome() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "rollHome" });
      }
    },
    allpk() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "allpk" });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.left {
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: auto;
}
span {
  vertical-align: super;
  font-size: 25px;
  color: #fff;
}
.bottombox {
  width: 242px;
  height: 114px;
  line-height: 114px;
  text-align: center;
  background-image: url("~@/assets/images/home/bottom.png");
  background-size: 100% 100%;
}
.bottomboxs {
  width: 243px;
  height: 115px;
  line-height: 114px;
  text-align: center;
  background-image: url("~@/assets/images/home/bottomactive.png");
  background-size: 100% 100%;
}
.bigKill {
  width: 135px;
  height: 135px;
  line-height: 154px;
  text-align: center;
  background-image: url("~@/assets/images/home/bigkill.png");
  background-size: 100% 100%;
  img {
    width: 70%;
  }
}
</style>