import axios from 'axios'
import { getToken } from './cookie';

const reqAxios = axios.create({
  //baseURL: 'http://119.3.177.18/prod-api/', // 所有请求的公共地址部分
  baseURL: 'https://api.blindboxes.net/',
  // baseURL: 'http://192.168.3.25:8080',
  // baseURL: 'http://localhost:8080/',

  // baseURL: 'https://test.5scsgo.com/prod-api/',
  timeout: 60000,// 请求超时时间 这里的意思是当请求时间超过5秒还未取得结果时 提示用户请求超时
})
// 拦截请求（拦截器）
reqAxios.interceptors.request.use(function (request) {
  // 处理请求公共数据，如添加请求头令牌...
  let token = getToken()
  //请求头设置
  request.headers = {
    'content-type': 'application/json;',
    "userType": 'user',
    "Authorization": token
  }
  return request;
}, function (error) {
  return Promise.reject(error);
})



//登录
export const getLogin = data => {
  return reqAxios.post('/login', data)
}
//注册
export const getRegister = data => {
  return reqAxios({
    method: 'post',
    url: '/api/register',
    data
  })
}
// 判断是否充值
export const IsRecharge = () => { 
  return reqAxios.get('/api/user/getUserIsRecharge')
}

// 刷新用户金币
export const updateUserData = () => { 
  return reqAxios.post('/api/user/updateUserData')
}

//获取验证码
export const getcode = phone => {
  return reqAxios.get(`/api/sms/getSmsMessage?phonenumber=${phone}`)
}

// 获取用户信息
export const getUserInfo = () => {
  return reqAxios.get('/getPtUserInfo')
}
//更新用户信息 /api/user/updateUserData
export const updateUserInfo = () => {
  return reqAxios.post('/api/user/updateUserData')
}
//roll房列表
export const getRollList = data => {
  return reqAxios.post(`/api/rollData/getRollList`, data)
}
//获取roll房信息
export const getROllActive = rollId => {
  return reqAxios.post(`/api/rollData/getRollDetail?rollId=${rollId}`,)
}
//加入roll房
export const getRoomsJoin = (rollId, pass) => {
  return reqAxios.post(`/api/rollData/joinRoll?rollId=${rollId}&password=${pass}`)
}

// 获取用户参与Roll房
export const getuserRoll = (pageSize, pageNum) => {
  return reqAxios.get(`/api/rollData/getRollListByUser?pageSize=${pageSize}&pageNum=${pageNum}`)
}

// 查询中奖记录
export const getUserWinner = rollId =>{
  return  reqAxios.post(`/api/rollData/getRollHisForRollById?rollId=${rollId}`)
}

//首页顶部轮播
export const getWeapon = options => {
  return reqAxios.get('/skinsservice/ttBanner/getWqList', options)
}


//首页中间轮播
export const getHomeBanner = options => {
  return reqAxios.get('/skinsservice/ttBanner/apiBannerList', options)
}

//首页尾部宝箱
export const getTreasureBox = options => {
  return reqAxios.post('/api/boxData/getBoxClassAndBoxData', options)
}

//首页跳转开箱,无需登录
export const getBoxDetail = options => {
  return reqAxios.get('/api/boxData/getBoxDetailById?boxId=' + options)
}

//开箱，箱子包含
export const boxContain = options => {
  return reqAxios.get('/api/boxData/getBoxOrnamentsListPTYH?boxId=' + options)
}

//开箱，最近掉落
export const newFallAPI = options => {
  return reqAxios.get('/api/boxData/getBoxOrnamentsRecords?boxId=' + options + '&recordsNum=22')
}

//开箱接口 
export const openBoxAPI = options => {
  return reqAxios({
    method: 'post',
    url: `/api/boxData/drawBox?boxId=${options.id}&drawCount=${options.count}`,
  })
}

//开箱，武器分解
export const decomposeWeaponAPI = options => {
  return reqAxios({
    method: 'post',
    url: `/api/boxData/exchangeBox?recordId=${options}`
  })
}

//全部对战，查询所有对战列表
export const searchPkAPI = () => {
  return reqAxios.post('/api/fightAgainstBData/getightAgainstDataList')
}

//创建对战
export const createPkAPI = data => {
  return reqAxios({
    method: 'post',
    url: '/api/fightAgainstBData/createFight',
    data
  })
}

//用户签到post
export const getUserSign = data => {
  return reqAxios({
    method: 'post',
    url: `/api/user/userSigin`,
    data
  })
}

//用户签到查询
export const getUserSigndata = data => {
  return reqAxios.get(`/api/user/userSigninData?signDate=${data}`)
}

//发送验证码

export const getCode = code => {
  return reqAxios.get(`/api/sms/getSmsMessage?phonenumber=${code}`)
}

//验证验证码
export const checkCode = data => {
  return reqAxios.get(`/api/sms/checkSMSCode?phonenumber=${data.phonenumber}&checkword=${data.checkword}`)
}

export const changePass = newdata => {
  return reqAxios.post('/api/updatePassword', newdata)
}

//充值查询
export const getUserchong = () => {
  return reqAxios.get(`/api/ttCoinItem/getCoinItemList`)
}

// /prod-api/api/jiujiaOrder/createOrder
//充值订单
export const Recgargewx = data => {
  return reqAxios({
    method: 'post',
    url: `/api/tianxinOrder/createOrder`,
    data
  })
}
// export const Recgargezfb = (coinItemId, coinItemAmount,) => {
//   return reqAxios({
//     method: 'get',
//     url: `/api/alipay/trade/page/pay?coinItemId=${coinItemId}&coinItemAmount=${coinItemAmount}&coinItemNum=1`
//   })
// }
export const Recgargezfb = (data) => {
 return reqAxios({
    method: 'post',
    url: `/api/jiujiaOrder/createOrder`,
    data
  })
}


// 商城物品兑换
export const shopgunAPI = options => {
  return reqAxios({
    method: 'post',
    url: `/api/user/exchangeO?oid=${options}`
  })
}
// 背包
export const getBag = (pageSize = 5000, pageNum = 1) => {
  return reqAxios.get(`/api/user/getUserPacksack?pageSize=${pageSize}&pageNum=${pageNum}`)
}
// 背包分解
export const shopsplitAPI = data => {
  return reqAxios({
    method: 'post',
    url: `/api/user/decomposeBbByIds`,
    data
  })
}
// 商城
export const getShop = (p, n, id, name) => {
  return reqAxios.get(`/api/user/getShoppingList?pageSize=${p}&pageNum=${n}&classId=${id}&oName=${name}`)
}
// 商城分类
export const getShopSort = () => {
  return reqAxios.get('/api/user/getOClass')
}
// 汰换
export const updateReplacement = data => {
  return reqAxios({
    method: 'post',
    url: `/skinsservice/replacementRecord/synthesizeItems`,
    data
  })
}
// 收支明细
export const getdetail = (pageSize = 15, pageNum = 1) => {
  return reqAxios.get(`/api/user/getBeanChangeRecords?pageSize=${pageSize}&pageNum=${pageNum}`)
}

// 绑定交易链接
export const giveMoneyAPI = options => {
  return reqAxios.post(`/api/user/bindSteamLink?steamLink=${options}`)
}
// 更新用户信息
export const updatauserAPI = data => {
  return reqAxios({
    method: 'post',
    url: '/api/user/updateProfile',
    data
  })
}
// 更新用户头像信息
export const updataImgAPI = data => {
  return reqAxios({
    method: 'post',
    url: '/api/user/updateUserAvatar',
    data
  })
}
// 绑定推广链接
export const giveUserAPI = options => {
  return reqAxios({
    method: 'post',
    url: `/api/user/bindInvitationCode?invitationCode=${options}`
  })
}
// 提取饰品
export const getExtractAPI = options => {
  return reqAxios({
    method: 'post',
    url: `/api/boxData/extractBox?recordId=${options}`
  })
}
// 提货账单
export const getExtractBillAPI = (pageSize = 10000, pageNum = 1) => {
  return reqAxios.get(`/api/deliveryApply/getDeliveryApplyList?pageSize=${pageSize}&pageNum=${pageNum}`)
}

//福利
export const getwelfare = num => {
  return reqAxios.get(`/api/welfare/getRechargeWelfare?acType=${num}`)
}
// 开启充值vip福利
export const opentwelfareAPI = options => {
  return reqAxios({
    method: 'post',
    url: `/api/welfare/openWelfareBox?aid=${options}`
  })
}
//新人福利
export const getnewwelfare = num => {
  return reqAxios.get(`/api/welfare/getWelfareList?acType=${num}`)
}
//CDK
export const getCDK = cdk => {
  return reqAxios.post(`/api/cdkData/drawCDK?cdk${cdk}`)
}
//文章  
export const getArticle = type => {
  return reqAxios.get(`/api/notice/getNoticeList?articleType=${type} `)
}
//获取对战房间中的用户列表
export const getRoomList = id => {
  return reqAxios.post(`/api/fightAgainstBData/getfightAgainstUserList?fid=${id}`)
}
//查询宝箱分类
export const boxesType = () => {
  return reqAxios.get('/api/fightAgainstBData/getBoxClass')
}
//查询对战宝箱列表
export const pkBoxes = (cid) => {
  return reqAxios.get(`/api/fightAgainstBData/list?cid=${cid}`)
}
//加入对战
export const joInPk = id => {
  return reqAxios.post(`/api/fightAgainstBData/joinFightAgainst?fId=${id}`)
}
//对战宝箱饰品
export const boxOrnament = (id) => {
  return reqAxios.get(`/api/fightAgainstBData/getFightAgainstBoxOrnamentsById?bid=${id}`)
}
//查询我参加的roll /api/rollData/getUserRollHistoryList
// export const getMyRoll = () => {
//   return reqAxios.get(`/api/rollData/getUserRollHistoryList`)
// }

// 对战历史记录  
export const getPklog = () => {
  return reqAxios.get(`/api/fightAgainstBData/getFightHis?pageNum=1&pageSize=500`)
}

//玩家对战记录 /api/fightAgainstBData/getFightDataByUid
export const getUserPklog = () => {
  return reqAxios.get(`/api/fightAgainstBData/getFightDataByUid?pageNum=1&pageSize=20`)
}
//对战结果
export const getPkResult = (fid) => {
  return reqAxios.get(`/api/fightAgainstBData/getFightAgainstResultOrnamentsById?fid=${fid}`)
}
//大逃杀 选择模式 /api/battleRoyale/selectRank
export const getbigkillmodel = (id) => {
  return reqAxios.get(`/api/battleRoyale/selectRank?rankId=${id}`)
}
///api/battleRoyale/joinRoom
export const getbigkillroom = (rankId, roomid, copies) => {
  return reqAxios.get(`/api/battleRoyale/joinRoom?rankId=${rankId}&roomId=${roomid}&copies=${copies}`)
}
// 大逃杀 历史记录 /api/battleRoyale/getHistoricalRecord
export const getbigkilHistroy = (rankId) => {
  return reqAxios.get(`/api/battleRoyale/getHistoricalRecord?rankId=${rankId}`)
}
//开箱一键分解
export const allDecomposeAPI = data => {
  return reqAxios({
    method: 'post',
    url: '/api/boxData/exchangeBoxList',
    data
  })
}
// 口令红包
// /prod-api/api/redPackData/getRedPackByKey
export const getred = cdk => {
  return reqAxios.post(`/api/redPackData/getRedPackByKey?redPackKey=${cdk}`)
}
// 实名认证
export const getusername = (realName, idCard) => {
  return reqAxios.post(`/api/idCardCheck?realName=${realName}&idCard=${idCard}`)
}

// 实名认证查询
// /api/realName/queryCertifyResult
export const queryusername = () => {
  return reqAxios.post('/api/realName/queryCertifyResult')
}
// 仓库
export const getuserhouse = () => {
  return reqAxios.post('/api/user/getUserWarehouseList')
}
// 仓库分解
export const Splithouse = id => {
  return reqAxios.post(`/api/user/decomposeFirstChargeBonus/${id}`)
}
// return reqAxios.post(`/api/rollData/joinRoll/${rollId}/${pass}`)

//卡密 /prod-api/api/ttCoinItem/checkKM/{key}
export const getkami = id => {
  return reqAxios.post(`/api/ttCoinItem/checkKM/${id}`)
}

// 查询用户金币 /api/user/getUserRealTimeBean

export const getuserbean = ()=> {
  return reqAxios.get(`/api/user/getUserRealTimeBean`)
}

 // /api/user/getBeanChangeRecords