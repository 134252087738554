<!-- 公告栏组件 -->
<template>
  <div class="notice-bar">
    <marquee :scrollamount="scrollamount" class="swiper-top-marquee" width="100%" behavior="scroll">
      <div v-html="text"></div>
    </marquee>
  </div>
</template>

<script>
import { getArticle } from "../api/index";
export default {
  data() {
    return {
      scrollamount: 6,
      text: ""
    };
  },
  created() {
    let type = 2;
    getArticle(type).then(res => {
      this.text = res.data.rows[0].content;
    });
  }
};
</script>
<style scoped lang='scss'>
.notice-bar {
  margin: 10px auto;
  position: relative;
  width: 50%;
  height: 48px;
  border-radius: 24px;
  font-size: 20px;
  color: #fff;
  background-color: #fefdfe1a;
  border: 1px solid rgba(255, 255, 255, 0.477);
  display: flex;
  align-items: center;
}

@media (max-width: 550px) {
  .notice-bar {
    margin: 10px auto;
    position: relative;
    width: 90%;
    height: 20px;
    font-size: 14px;
    border-radius: 24px;
    color: #fff;
    background-color: #fefdfe1a;
    border: 1px solid rgba(255, 255, 255, 0.477);
    display: flex;
    align-items: center;
  }
}
</style>

