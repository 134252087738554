<template>
  <div>
    <!-- 协议弹窗 -->
    <!-- <div @click="isShow=true">
      <img width="188px" height="118px" src="@/assets/images/user/chong.png" alt />
    </div>-->
    <div v-if="isShow" class="xieyi tanchuang">
      <!-- <div id="aa" class="zhifu1"></div> -->

      <div class="rechargeBox">
        <div class="per">
          <img src="@/assets/roll/per.png" alt />
        </div>
        <div class="close" @click="isShow = false"></div>
        <div class="zhifu t-c color-white f-20 d-flex j-a" v-if="pc">
          <!-- <div class="zfb" :class="state==1?'glod':''" @click="zfb">卡密链接</div> -->
          <div class="zfb" :class="state == 1 ? 'glod' : ''" @click="zfb">
            支付宝
          </div>
          <!-- <div class="wx" :class="state==2?'glod':''" @click="wx">微信</div> -->
        </div>
        <div v-show="iskami" class="moneyNum d-flex f-w justify">
          <div v-for="(item, index) in rows" :key="index" class="moneyItem">
            <div
              @click="chong(item.price, item.itemId)"
              :class="chongzhi == item.price ? 'chonga' : 'moneyNumItem'"
              class="d-flex f-w"
            >
              <div class="money t-c">{{ item.price }}</div>
            </div>
          </div>
        </div>

        <div>
          <div class="kami d-flex j-a">
            <input type="text" class="color-white" v-model="kami" />
            <div class="t-c kabtn" @click="kamichong">卡密充值</div>
          </div>
          <div
            class="sure t-c color-g zt"
            style="font-size: 30px"
            @click="zfbUrl"
          >
            确认充值
          </div>
          <div v-if="!pc" class="tanchuangwx">
            <div class="wxclose" v-if="pc" @click="wxewm = false"></div>
            <img v-if="state == 2" :src="img" alt />
            <img v-if="state == 1" :src="ewmsrc" alt />

            <div ref="qrcode" id="qrcode"></div>
          </div>
          <div v-if="wxewm && pc" class="bg1"></div>
        </div>
        <div class="zhifu color-white t-c weight" v-if="!pc">
          <div class="title f-13">实付金额</div>
          <div class="moneynum">￥{{ chongzhi }}</div>
          <div class="d-flex j-a" style="width: 120%">
            <div class="zfb" :class="state == 1 ? 'glod' : ''" @click="zfb">
              支付宝
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShow" class="bg"></div>
    <!-- 充值 -->
  </div>
</template>

<script>
import {
  getUserchong,
  Recgargewx,
  Recgargezfb,
  queryusername,
  getkami,
  IsRecharge,
  updateUserData,
} from "../../api/index";
import QRcode from "qrcode";
export default {
  inject: ["reload"],
  data() {
    return {
      isShow: false,
      chongzhi: 0,
      rows: [],
      url: "",
      img: "",
      pc: false,
      state: 1,
      data: {},
      wxewm: false,
      ewmsrc: "",
      kami: "",
      iskami: true,
      ischong: false,
      aa: "",
      coinItemId: "",
      coinItemAmount: "",
    };
  },
  methods: {
    kamichong() {
      getkami(this.kami).then((res) => {
        this.$message(res.data.msg);
      });
    },
    async qrcode1() {
      this.ewmsrc = await QRcode.toDataURL(this.url);
    },
    zfb() {
      this.state = 1;
    },
    wx() {
      this.state = 2;
    },
    close() {
      this.$router.go(0);
    },
    chong(price, id) {
      this.chongzhi = price;
      this.data = {
        coinItemId: id,
        coinItemAmount: price,
        coinItemNum: 1,
      };
    },

    zfbUrl() {
      if (this.state == 1) {
        if (this.chongzhi == 0) {
          this.$message.error("请选择金额！");
        } else {
          Recgargezfb(this.data).then((res) => {
            console.log(res);
            if (res.data.code == 500) {
              this.$message.error("请先实名认证！");
            } else {
              //   let bb = res.data.replace(
              //     "none",
              //     "block;font-size:18px;color:#FFD700;"
              //   );
              //   let aahtml = document.getElementById("aa");
              //   aahtml.innerHTML = bb;
              // }
              if (this.pc) {
                window.open(res.data.data.url);
              } else {
                this.url = res.data.data.url;
              }
              this.qrcode1();

              console.log(res);
            }
          });
        }
      } else if (this.state == 2) {
        //微信支付
        if (this.chongzhi == 0) {
          this.$message.error("请选择金额！");
        }
        Recgargewx(this.data).then((res) => {
          if (res.data.code == 500) {
            this.$message.error(res.data.msg);
          }
          this.wxewm = true;
          this.img = res.data.data.img;
        });
      }
    },
  },
  mounted() {
    window.addEventListener("load", () => {
      //写入你想要执行的代码
      updateUserData().then((res) => {
        res;
        this.reload();
      });
    });
    IsRecharge().then((res) => {
      console.log(res.data.data);
    });
    this.$bus.$on("chongzhi", (res) => {
      this.isShow = res;
      if (this.isShow) {
        getUserchong().then((res) => {
          this.rows = res.data.rows;
        });
      }
    });
    if (window.innerWidth > 550) {
      this.pc = false;
    } else {
      this.pc = true;
    }
    queryusername().then(() => {});
  },
  created() {
    getUserchong().then((res) => {
      this.rows = res.data.rows;
    });
  },
};
</script>

<style scoped lang="scss">
.zhifu1 {
  position: absolute;
  top: 52%;
  left: 87%;
  z-index: 999;
}
.glod {
  font-weight: bold;
  color: gold;
}
.tanchuang {
  width: 50%;
  top: 16%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 10px);
  z-index: 90;
  background: url("~@/assets/roll/rechargebg.png") no-repeat;
  background-size: 100% 100%;
  padding: 50px;
  box-sizing: border-box;
}
.tanchuangwx {
  width: 16% !important;
  top: 229px;
  position: fixed;
  z-index: 90;
  background: none;
  padding: 0px;
  right: 63px;
  height: 200px;
  .wxclose {
    float: right;
    width: 40px;
    height: 40px;
    background: url("~@/assets/images/pk/close.png") no-repeat;
    background-size: 100% 100%;
  }
  img {
    display: block;
    margin: auto;
    width: 100% !important;
  }
}
.rechargeBox {
  width: 100%;
  margin: auto;
  position: relative;
  .per {
    position: absolute;
    top: -100%;
    left: -85px;
    img {
      height: 530px;
    }
  }
  .close {
    position: absolute;
    width: 50px;
    height: 50px;
    top: -153px;
    right: -80px;
    background: url("~@/assets/images/pk/close.png") no-repeat;
    background-size: 100% 100%;
  }
  .kami {
    width: 290px;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    input {
      width: 70%;
      height: 100%;
      outline: none;
      color: white;
      border: 1px solid #0197f7;
      font-size: 18px;
      text-align: left;
      padding-left: 5px;
      margin-left: 5px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0);
    }
    .kabtn {
      background-color: #064780;
      color: #fff;
      padding: 3px;
      border-radius: 5px;
      margin-left: 5px;
    }
    @media (max-width: 550px) {
      bottom: -75px;
    }
  }
  .sure {
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 550px) {
      bottom: -45px;
    }
  }
}

.money {
  width: 60%;
  height: 50px;
  line-height: 50px;
  margin: auto;
}
.text {
  color: #e7b781;
  margin: auto;
  height: 50px;
  line-height: 40px;
  font-size: 10px;
}
.chonga {
  width: 130px;
  height: 88px;
  line-height: 88px;
  background: url("~@/assets/roll/chonga.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  font-size: 22px;
  color: #fff;
}
.zhifu {
  position: absolute;
  top: -50px;
  right: 25px;
  .moneynum {
    font-size: 35px;
    color: gold;
    margin: 10px auto;
  }
  .zfb,
  .wx {
    margin: 5px 0;
    width: 100px;
    height: 45px;
    font-size: 18px;
    line-height: 45px;
    background: url("~@/assets/roll/btn215.png") no-repeat;
    background-size: 100% 100%;
  }

  img {
    vertical-align: middle;
    width: 150px;
  }
}

// 背景隐藏层
.bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 9%;
  z-index: 80;
  background-color: rgba(0, 0, 0, 0.726);
}
.bg1 {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 9%;
  z-index: 80;
  background-color: rgba(0, 0, 0, 0);
}
.moneyNum {
  width: 40%;
  margin: 85px auto;

  .moneyNumItem {
    width: 130px;
    height: 88px;
    line-height: 88px;
    background: url("~@/assets/roll/chong.png") no-repeat;
    background-size: 100% 100%;

    text-align: center;
    font-size: 22px;
    color: #fff;
  }
}
@media (max-width: 550px) {
  .tanchuang {
    width: 100% !important;
    top: 25px;
    position: fixed;
    z-index: 90;
    background: none;
    padding: 0px;
  }
  .tanchuangwx {
    width: 100% !important;
    top: 30px;
    position: fixed;
    z-index: 90;
    background: none;
    padding: 0px;
    left: 0%;
    .wxclose {
      float: right;
      width: 40px;
      height: 40px;
      background: url("~@/assets/images/pk/close.png") no-repeat;
      background-size: 100% 100%;
    }
    img {
      display: block;
      margin: 50px auto;
      width: 50% !important;
    }
  }
  .rechargeBox {
    width: 100%;
    margin: 50px auto;
    position: relative;
    .per {
      display: none;
    }
    .close {
      position: absolute;
      width: 50px;
      height: 50px;
      top: -50px;
      right: 15px;
      background: url("~@/assets/images/pk/close.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .money {
    width: 60%;
    height: 50px;
    line-height: 50px;
    margin: auto;
  }
  .text {
    color: #e7b781;
    margin: auto;
    height: 50px;
    line-height: 40px;
    font-size: 10px;
  }
  .chonga {
    width: 130px;
    height: 88px;
    line-height: 88px;
    background: url("~@/assets/roll/chonga.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-size: 22px;
    color: #fff;
  }
  .zhifu {
    position: relative;
    top: 5px;
    margin-left: 58px;

    .title,
    .moneynum {
      display: none;
    }
    .zfb,
    .wx {
      margin: 5px 0;
      width: 130px;
      height: 45px;
      line-height: 45px;
      background: url("~@/assets/roll/btn215.png") no-repeat;
      background-size: 100% 100%;
    }

    img {
      vertical-align: middle;
      width: 100px;
    }
  }

  // 背景隐藏层
  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 9%;
    z-index: 80;
    background-color: rgba(3, 3, 3, 0.9);
  }

  .moneyNum {
    width: 80%;
    margin: 42px auto;

    .moneyNumItem {
      width: 130px;
      height: 88px;
      line-height: 88px;
      background: url("~@/assets/roll/chong.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 22px;
      color: #fff;
    }
  }
  .zhifu1 {
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }
}
</style>
