<template>
  <div class="bgc">
    <div :class="`${$route.name === 'openbox' ? 'bignavs' : 'bignav'}`">
      <el-container class="container">
        <el-header class="d-flex">
          <div class="d-flex logobox" style="align-items: center">
            <div class="logo">
              <img src="@/assets/images/logo.png" alt />
            </div>
            <HeaderNav class="HeaderNav"></HeaderNav>
          </div>
          <Login></Login>
        </el-header>
        <el-main>
          <!-- :class="`${$route.name === 'Home' ? 'swiper' : 'disnone'}`" -->
          <!-- 手机 -->
          <div v-show="isshow == true">
            <NoticeBar v-show="show == true"></NoticeBar>
          </div>
          <!-- 手机 -->
          <div v-show="isshow == true">
            <div
              style="width: 98vw; margin: auto; overflow: hidden"
              v-show="user == true"
            >
              <vue-seamless-scroll
                :data="images"
                class="swiper"
                :class-option="optionSingleHeightTime"
              >
                <div class="swiper-item">
                  <!-- @click="$router.push(`/openbox/${item.bid}`)" -->
                  <div
                    class="items"
                    v-for="(item, index) in images"
                    :key="index"
                    :class="`gunbox-${item.ocolor}`"
                  >
                    <div class="straightbox">
                      <div class="straight">
                        <img :src="item.img" alt />
                        <div class="im">{{ item.name }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
          <!-- 电脑 -->
          <div v-show="isshow == false">
            <div style="width: 98vw; margin: auto; overflow: hidden">
              <vue-seamless-scroll
                :data="images"
                class="swiper"
                :class-option="optionSingleHeightTime"
              >
                <div class="swiper-item">
                  <!-- @click="$router.push(`/openbox/${item.bid}`)" -->
                  <div
                    class="items"
                    v-for="(item, index) in images"
                    :key="index"
                    :class="`gunbox-${item.ocolor}`"
                  >
                    <div class="straightbox">
                      <div class="straight">
                        <img :src="item.img" alt />
                        <div class="im">{{ item.name }}</div>
                      </div>
                      <!-- <div class="side">
                      <img :src="item.uimg" alt />
                      </div> -->
                    </div>
                  </div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
          <sideVue></sideVue>
          <!-- <keep-alive include="Open">
            <router-view></router-view>
          </keep-alive> -->
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
          <!-- <router-view /> -->
          <!-- 电脑 -->
          <div v-show="isshow == false">
            <NoticeBar></NoticeBar>
          </div>
          <pcBottom
            class="pcbottom"
            :class="`${$route.name === 'openbox' ? 'display' : ''}`"
          ></pcBottom>
        </el-main>
      </el-container>
      <bottomNav class="bottomNav"></bottomNav>
    </div>
    <div class="tanchuang1" v-if="close == true">
      <div @click="close = false" class="closebg"></div>
      <h2>{{ title }}</h2>
      <div v-html="text"></div>
    </div>
    <div class="bottom d-flex">
      <div class="left">
        <span>FIRECSGO.COM</span>
        <div class="text">
          CS:GO case opening.Win rare skins at best prices.
          <br />This site not associated with Valve Corp. <br />These Terms of
          Use affect User's rights and impose certain obligations while using
          the Website， <br />so the User must read them carefully.
          <br />
        </div>
      </div>
      <div class="middle">
        <p>鲁ICP备2022019242号-3</p>
        <p>盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</p>
      </div>
      <div class="right">
        <div class="main f-13 d-flex">
          <div class="yhxy" @click="(close = true), (type = 1)">
            <img src="../../assets/images/home/user.png" alt />
            <p>用户协议</p>
          </div>
          <div class="ysxy" @click="(close = true), (type = 0)">
            <img src="../../assets/images/home/ysxy.png" alt />
            <p>隐私协议</p>
          </div>
          <div class="help" @click="(close = true), (type = 2)">
            <img src="../../assets/images/home/help.png" alt />
            <p>帮助中心</p>
          </div>
          <div class="about" @click="(close = true), (type = 3)">
            <img src="../../assets/images/home/about.png" alt />
            <p>反洗钱协议</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bottomNav from "./common/bottomNav";
import HeaderNav from "./common/headerNav";
import Login from "./common/login";
import NoticeBar from "../../components/noticeBar";
import { newFallAPI } from "../../api";
import vueSeamlessScroll from "vue-seamless-scroll";
import { getArticle } from "../../api/index";
import pcBottom from "./common/pcBottom.vue";
import sideVue from "../../components/side.vue";
export default {
  components: {
    HeaderNav,
    Login,
    bottomNav,
    NoticeBar,
    vueSeamlessScroll,
    pcBottom,
    sideVue,
  },
  data() {
    return {
      images: [],
      text: "",
      title: "",
      close: false,
      type: 1,
      show: true,
      isshow: false,
      screenWidth: null,
      user: true,
    };
  },
  methods: {},
  computed: {
    optionSingleHeightTime() {
      return {
        // singleHeight: 50,
        // waitTime: 1500,
        step: 0.8, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动)
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    newFallAPI("").then((res) => {
      // console.log(res);
      this.images = res.data.data;
    });
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  updated() {
    //文章接口
    getArticle(1).then((res) => {
      this.title = res.data.rows[this.type].title;
      this.text = res.data.rows[this.type].content;
    });
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to) {
        if (to.name == "Home") {
          this.show = true;
          this.user = true;
          newFallAPI("").then((res) => {
            this.images = res.data.data;
          });
        } else if (
          to.name == "backpack" ||
          to.name == "personalCenter" ||
          to.name == "allpk" ||
          to.name == "replacing" ||
          to.name == "rollHome" ||
          to.name == "bigKill" ||
          to.name == "pklog" ||
          to.name == "create" ||
          to.name == "Shopping" ||
          to.name == "bill" ||
          to.name == "detail" ||
          to.name == "watch" ||
          to.name == "house" ||
          to.name == "waitepk"
        ) {
          this.show = false;
          this.user = false;
        } else if (to.name != "backpack" || to.name != "personalCenter") {
          this.user = true;
        }
      },
    },
    screenWidth: {
      handler: function (val) {
        if (val < 550) {
          this.isshow = true;
        } else {
          this.isshow = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.display {
  display: none;
}

.bgc {
  background-color: #232a37;
  .bignav {
    height: 1020px;
    background-image: url(~@/assets/images/home/background-img.png);
    background-size: 100% 100%;
    width: 100%;
    overflow: hidden;

    ::-webkit-scrollbar {
      display: none;
    }

    /deep/.el-header {
      height: 90px !important;
      width: 100%;
      padding: 0 10px !important;
      align-items: center;
      justify-content: space-between;
      background-image: url("~@/assets/images/bottomNav/headNavBg.png");
      background-size: 100% 100%;

      .logo {
        margin-left: 30px;
        margin-right: 70px;
        margin-top: -25px;

        @media (max-width: 550px) {
          margin-right: 26px;
        }

        img {
          width: 154px;
          height: 50px;
        }
      }

      .HeaderNav {
        display: none;

        @media (min-width: 450px) {
          display: flex;
          width: 850px;
        }
      }
    }

    /deep/.el-main {
      padding: 0 !important;
      min-height: 100vh !important;

      @media (min-width: 450px) {
        margin-bottom: 0 !important;
        min-height: 100vh !important;
      }

      .swiper {
        margin-bottom: 2px;

        .swiper-item {
          display: flex;
          overflow: hidden;

          .items {
            // flex: none;
            width: 180px;
            height: 120px;
            @media (max-width: 550px) {
              width: 110px;
              height: 80px;
            }

            .straightbox {
              width: 100%;
              height: 100%;

              @media (max-width: 550px) {
                width: 110px;
                height: 80px;
              }

              .straight {
                //width: 100%;
                height: 100%;
                // position: absolute;
                // transition: all 1s ease;

                // z-index: 888;
                @media (max-width: 550px) {
                  width: 110px;
                  height: 80px;
                }

                img {
                  display: block;
                  width: 150px;
                  margin: auto;
                  height: 90px;

                  @media (max-width: 550px) {
                    width: 90px;
                    height: 60px;
                    margin: 4px auto 0px;
                  }
                }
                .im {
                  width: 80%;
                  margin: auto;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  color: #fff;
                  font-size: 14px;

                  @media (max-width: 550px) {
                    font-size: 12px;
                    margin: -4px auto;
                  }
                }
              }
            }
          }

          .straightbox:hover .straight {
            top: 150px;
            transition: all 1s ease;
          }

          .straightbox:hover .side {
            top: 0;
            transition: all 1s ease;
          }
        }
      }
    }

    .bottomNav {
      @media (min-width: 450px) {
        display: none;
      }
    }
  }

  .bignavs {
    height: 1500px;
    background-image: url(~@/assets/images/home/background-imgs.png);
    background-size: 100% 100%;
    width: 100%;

    @media (max-width: 550px) {
      height: 1200px;
      background-image: url(~@/assets/images/home/background-imgs-m.png);
      background-size: 100% 100%;
    }

    overflow: hidden;

    ::-webkit-scrollbar {
      display: none;
    }

    /deep/.el-header {
      height: 90px !important;
      width: 100%;
      padding: 0 10px !important;
      align-items: center;
      justify-content: space-between;
      background-image: url("~@/assets/images/bottomNav/headNavBg.png");
      background-size: 100% 100%;

      .logo {
        margin-left: 0px;
        margin-right: 70px;
        margin-top: -25px;

        img {
          width: 154px;
          height: 50px;
        }
      }

      .HeaderNav {
        display: none;

        @media (min-width: 450px) {
          display: flex;
          width: 850px;
        }
      }
    }

    /deep/.el-main {
      padding: 0 !important;
      overflow: hidden;

      .swiper {
        margin-bottom: 2px;

        .swiper-item {
          display: flex;
          overflow: hidden;

          .items {
            flex: none;
            width: 180px;
            height: 120px;

            @media (max-width: 550px) {
              width: 110px;
              height: 80px;
            }

            .straightbox {
              width: 180px;
              height: 120px;
              position: relative;

              @media (max-width: 550px) {
                width: 110px;
                height: 80px;
              }

              .straight {
                width: 100%;
                height: 100%;
                position: absolute;
                transition: all 1s ease;

                @media (max-width: 550px) {
                  width: 110px;
                  height: 80px;
                }

                img {
                  display: block;
                  width: 150px;
                  margin: auto;
                  height: 90px;

                  @media (max-width: 550px) {
                    width: 90px;
                    height: 60px;
                    margin: 4px auto 0px;
                  }
                }

                .im {
                  width: 80%;
                  margin: auto;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  color: #fff;
                  font-size: 14px;

                  @media (max-width: 550px) {
                    font-size: 12px;
                    margin: -4px auto;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bottomNav {
      @media (min-width: 450px) {
        display: none;
      }
    }
  }

  .tanchuang1 {
    position: fixed;
    z-index: 99;
    width: 50%;
    // height: 800px;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 10px;
    padding: 15px 10px;
    margin: 20px;
    /* 毛玻璃模糊背景 */
    background-color: rgba(36, 35, 35, 0.8);
    backdrop-filter: blur(6px);
    color: white;
    // text-align: center;
    font-size: 25px;
    overflow: auto;

    .closebg {
      width: 75px;
      height: 75px;
      background: url("~@/assets/roll/closs.png") no-repeat;
      background-size: 100% 100%;
      float: right;
    }
  }

  .tanchuang1::-webkit-scrollbar {
    width: 1px; //修改滚动条宽度
  }

  .bottom {
    width: 100%;
    margin: auto;
    justify-content: space-between;
    background-image: url("~@/assets/images/home/bottomBg.png");
    background-size: 100% 100%;

    @media (max-width: 550px) {
      width: 100%;
      display: none !important;
    }

    .left {
      margin: 25px 0;

      span {
        font-size: 25px;
        color: #ccc;
      }

      .text {
        font-size: 12px;
        color: #ccc;
        width: 420px;
      }
    }
    .middle {
      margin-top: 110px;
      font-size: 12px;
      text-align: center;
      color: #ccc;
    }
    .main {
      width: 350px;
      color: #ccc;
      margin: 25px 0;
      justify-content: space-around;
    }
  }
}

.disnone {
  display: none !important;
}

@media (max-width: 550px) {
  .bgc {
    width: 100%;

    .bignav {
      min-height: 830px;
      height: auto;
      background: url("~@/assets/images/home/phone/background-img.png")
        no-repeat;
      background-size: 100% 100%;

      /deep/.el-header {
        height: 50px !important;
        width: 100%;
        background-color: #060e30;
        padding: 0 10px !important;
        align-items: center;
        justify-content: space-between;
        background-image: none;
        .logo {
          margin-left: 0px;
          margin-top: -5px;

          img {
            width: 100px;
            height: 30px;
          }
        }
      }

      .pcbottom {
        display: none;
      }
    }
  }
}
@media (max-width: 550px) {
  /deep/.container {
    height: 100%;
  }
  .bgc .bignavs /deep/ .el-header {
    height: 50px !important;
    width: 100%;
    padding: 0 10px !important;
    align-items: center;
    justify-content: space-between;
    background-image: none;
    background-color: #060e30;

    .logo {
      margin-left: 0px;
      margin-top: -5px;

      img {
        width: 100px;
        height: 30px;
      }
    }

    .HeaderNav {
      display: none;
    }
  }
}
</style>
