<template>
  <!-- 手机端底部导航 -->
  <div class="nav">
    <div @click="goHome" :class="status == 1 ? 'light' : 'dark'">
      <img src="@/assets/images/bottomNav/phone/open.png" alt />
      <div>首页</div>
    </div>
    <!-- 游戏中心 -->
    <div @click="gameCenter" :class="status == 2 ? 'light' : 'dark'">
      <img src="@/assets/images/bottomNav/phone/roll.png" alt />
      <div>游戏中心</div>
    </div>
    <!-- 背包 -->
    <div @click="chognzhi" :class="status == 3 ? 'light' : 'dark'">
      <img src="@/assets/images/bottomNav/phone/backpark.png" alt />
      <div>充值</div>
    </div>
    <!-- 个人中心 -->
    <div @click="userCenter" :class="status == 4 ? 'light' : 'dark'">
      <img src="@/assets/images/bottomNav/phone/peoplecenter.png" alt />
      <div>个人中心</div>
    </div>
    <!-- 游戏中心弹窗 -->
    <div class="ct" v-show="show">
      <div @click="bigkill">大逃杀</div>
      <div @click="pk">竞技房</div>
      <div @click="roll">Roll</div>
      <div @click="replace">汰换</div>
      <div @click="openbox">盲盒开箱</div>
    </div>
    <div class="bg" v-show="show" @click="zhezhao"></div>
  </div>
</template>

<script>
import { getToken } from "../../../api/cookie";
export default {
  data() {
    return {
      table: false,
      status: 1,
      show: false
    };
  },
  methods: {
    bigkill() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "bigKillhome" });
      }
    },
    zhezhao() {
      this.show = false;
    },
    goHome() {
      this.status = 1;
      this.$router.push({ name: "Home" });
    },
    gameCenter() {
      this.status = 2;
      if (this.show == false) {
        this.show = true;
      } else if (this.show == true) {
        this.show = false;
      }
    },
    chognzhi() {
      let token = getToken();
      this.status = 3;
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$bus.$emit("chongzhi", true);
      }

      // this.$router.push({ name: "backpack" });
    },
    userCenter() {
      let token = getToken();

      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "personalCenter" });
      }
      this.status = 4;
    },
    pk() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "Pk" });
      }
      this.show = false;
    },
    roll() {
      let token = getToken();

      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "rollHome" });
      }
      this.show = false;
    },
    replace() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "replacing" });
      }
      this.show = false;
    },
    openbox() {
      this.show = false;
      this.$router.push({ name: "Open" });
    }
  }
};
</script>

<style scoped lang="scss">
.nav {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0px;
  height: 55px;
  background-color: #031030;
  align-items: center;
  z-index: 99;
  .light {
    color: rgba(15, 127, 254, 1);
  }

  .dark {
    color: #fff;
  }

  div {
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
    box-sizing: border-box;
    display: block;
    align-items: center;
    justify-content: center;

    img {
      width: 25px;
      height: 25px;
    }
  }

  .ct {
    display: block;
    width: 24%;
    height: 200px;
    background-color: rgba(1, 14, 45, 1);
    position: absolute;
    top: -200px;
    left: 26%;
    z-index: 99;


    div {
      line-height: 40px;
      font-size: 16px;
      height: 40px;
    }
  }
  // 背景隐藏层
  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    background-color: rgba(0, 0, 3, 0);
  }
}
</style>