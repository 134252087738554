<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
    <audio preload="auto" id="open" src="./assets/music/open.mp3"></audio>
    <audio preload="auto" id="audio" src="./assets/music/4秒.aac"></audio>
  </div>
</template>
<script>
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  mounted() {
    // if (window.innerWidth < 550) {
    //   this.$router.push({ name: "Home" });
    // }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>
<style>
</style>
