import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: '/Home',
    children: [
      {
        path: 'Home',
        name: 'Home',
        component: () => import('../views/Home/index.vue')
      },
      // 大逃杀
      {
        path: 'bigKill',
        name: 'bigKill',
        component: () => import('../views/BigKill/index.vue')
      },
       // 大逃杀选择房间
      {
        path: 'bigKillhome',
        name: 'bigKillhome',
        component: () => import('../views/BigKill/common/home.vue')
      },
      // 大逃杀进入房间
      {
        path: 'bigKillgethome/:id',
        name: 'bigKillgethome',
        component: () => import('../views/BigKill/common/gethome.vue')
      },
      {
        path:'Open',
        name:'Open',
        component:()=> import('../views/Home/common/open.vue'),
        meta:{
          keepAlive:true // 需要缓存
      }
      },
      {
        path: 'rollHome',
        name: 'rollHome',
        component: () => import('../views/rollHome/index.vue')
      },
      {
        path: 'Dream',
        name: 'Dream',
        component: () => import('../views/Dream/index.vue')
      },
      {
        path: 'Pk',
        name: 'Pk',
        redirect: 'Pk/allpk',
        component: () => import('../views/pk/index.vue'),
        children: [{
          path: 'allpk',
          name: 'allpk',
          component: () => import('../views/pk/common/allpk.vue'),
        },
        {
          path: 'pklog',
          name: 'pklog',
          component: () => import('../views/pk/common/pklog.vue'),
        },
        ]
      },
      {
        path: 'create',
        name: 'create',
        component: () => import('../views/pk/common/mypk.vue'),
      },
      {
        path: 'watch/:id',
        name: 'watch',
        component: () => import('../views/watch/index.vue')
      },
      // {
      //   path: 'create',
      //   name: 'create',
      //   component: () => import('../views/pk/create.vue')
      // },
      {
        path: 'Shopping',
        name: 'Shopping',
        component: () => import('../views/shopping/index.vue')
      },
      {
        path: 'User',
        name: 'User',
        redirect: 'User/personalCenter',
        component: () => import('../views/User/index.vue'),
        children: [
          // 个人中心
          {
            path: 'personalCenter',
            name: 'personalCenter',
            component: () => import('../views/User/common/PersonalCenter.vue'),
          },
          //背包  
          {
            path: 'backpack',
            name: 'backpack',
            component: () => import('../views/User/common/backpack.vue'),
          },
          // 仓库
          {
            path: 'house',
            name: 'house',
            component: () => import('../views/User/common/house.vue'),
          },
          //提货账单
          {
            path: 'bill',
            name: 'bill',
            component: () => import('../views/User/common/bill.vue'),
          },
          //收支明细
          {
            path: 'detail',
            name: 'detail',
            component: () => import('../views/User/common/detail.vue')
          },
        ]
      },
      //充值明细
      {
        path: 'TopupDetail',
        name: 'TopupDetail',
        component: () => import('../views/User/common/Top-up-Detail.vue')
      },
      //  会员推广
      {
        path: 'VIP',
        name: 'VIP',
        component: () => import('../views/User/common/VIP.vue')
      },
      {
        path: 'active/:rollId',
        name: 'active',
        component: () => import('../views/rollHome/active.vue')
      },
      {
        path: 'useractive/:rollId',
        name: 'useractive',
        component: () => import('../views/rollHome/useractive.vue')
      },
      {
        path: 'Recharge',
        name: 'Recharge',
        component: () => import('../views/User/Recharge.vue')
      },
      // 福利中心
      {
        path: 'Welfare',
        name: 'Welfare',
        redirect: 'Welfare/activeCenter',
        component: () => import('../views/Welfare/index.vue'),
        children: [
          // 福利中心
          {
            path: 'activeCenter',
            name: 'activeCenter',
            component: () => import('../views/Welfare/common/activeCenter.vue')
          },
          // 新人福利
          {
            path: 'newwelfare',
            name: 'newwelfare',
            component: () => import('../views/Welfare/common/newwelfare.vue')
          },
          // 会员中心
      {
        path: 'vipCenter',
        name: 'vipCenter',
        component: () => import('../views/Welfare/common/vipCenter.vue')
      },
      // 对战福利
      {
        path: 'pxWelfare',
        name: 'pxWelfare',
        component: () => import('../views/Welfare/common/pxWelfare.vue')
      },
      // 充值福利
      {
        path: 'rechargeWelfare',
        name: 'rechargeWelfare',
        redirect: 'rechargeWelfare/150cj',
        component: () => import('../views/Welfare/common/rechargeWelfare.vue'),
        children:[
          {
            path: '150cj',
            name: '150cj',
            component: () => import('../views/Welfare/choujiang/150cj.vue')
          },
          {
            path: '200cj',
            name: '200cj',
            component: () => import('../views/Welfare/choujiang/200cj.vue')
          },
          {
            path: '300cj',
            name: '300cj',
            component: () => import('../views/Welfare/choujiang/300cj.vue')
          },
          {
            path: '500cj',
            name: '500cj',
            component: () => import('../views/Welfare/choujiang/500cj.vue')
          },
        ]
      },
        ]
      },
      // ̭汰换
      {
        path: 'replacing',
        name: 'replacing',
        component: () => import('../views/replacing/index.vue')
      },
      {
        path: 'openbox/:id',
        name: 'openbox',
        component: () => import('../views/openBox/index.vue'),
        meta: {iconImg:'background-img.png'},
      },
      {
        path: 'waitepk/:id',
        name: 'waitepk',
        component: () => import('../views/waitepk/index.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
