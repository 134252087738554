<template>
  <!-- 电脑端上方导航 -->
  <div class="nav zt">
    <!-- 首页 -->
    <div
      @click="$router.push({ name: 'Home' })"
      :class="`${$route.name === 'Home' ? 'nav-texts' : 'nav-text'}`"
    >
      <img src="@/assets/images/bottomNav/Home.png" alt />
    </div>
    <!-- 个人中心-->
    <!-- <div
      @click="joinUser"
      :class="`${$route.name === 'personalCenter' ? 'nav-texts' : 'nav-text'}`"
    >
      <img src="@/assets/images/bottomNav/User.png" alt />
    </div>-->
    <!-- 商城 -->
    <div @click="Shopping" :class="`${$route.name === 'Shopping' ? 'nav-texts' : 'nav-text'}`">
      <img src="@/assets/images/bottomNav/Shopping.png" alt />
    </div>
    <!-- 背包 -->
    <div @click="backpack" :class="`${$route.name === 'backpack' ? 'nav-texts' : 'nav-text'}`">
      <img src="@/assets/images/bottomNav/backpark.png" alt />
    </div>
    <!-- 福利 -->
    <div @click="fuli" :class="`${$route.name === 'activeCenter' ? 'nav-texts' : 'nav-text'}`">
      <img src="@/assets/images/bottomNav/welfare.png" alt />
    </div>
  </div>
</template>

<script>
import { getToken } from "../../../api/cookie";

export default {
  data() {
    return {};
  },
  methods: {
    fuli() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "Welfare" });
      }
    },
    joinUser() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "User" });
      }
    },
    backpack() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "backpack" });
      }
    },
    Shopping() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$router.push({ name: "Shopping" });
      }
    }
  },
  mounted() {}
};
</script>

<style scoped lang='scss'>
.nav {
  font-size: 20px;
  div {
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    img {
      display: inline-block;
      margin: auto;
      width: 85px;
      height: 17px;
    }
  }
}
</style>