import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId:0,
    userGold: 0,
    userIntegral: 0,
    userName: '',
    selcetboxs: [],
    roomInformation: {},
    userAvater: '',
    userSign: 0,
  },
  mutations: {
    getUserGold(state, data) {
      state.userGold=data
    },
    getUserIntergral(state, data) {
      state.userIntegral=data
    },
    getUserID(state, data) {
     state.userId=data
    },
    getUserName(state, data) {
      state.userName=data
    },
    getselectBoxes(state, data) {
      state.selcetboxs=data
    },
    getroomInformation(state, data) {
      state.roomInformation=data
    },
    getuserAvater(state, data) {
      state.userAvater=data
    },
     getUserSign(state, data) {
     state.userSign=data
   }
  },
  actions: {
    
  },
  modules: {
  },
  //数据持久化
  plugins: [
		createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
			storage: window.localStorage,
      // 存储的 key 的key值
			key: "store",
			render(state) {
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
				return { ...state };
			}
		})
	]
})
