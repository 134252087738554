<template>
  <div class="d-flex" style="align-items: center">
    <!-- 充值 -->
    <div>
      <img
        class="chong"
        @click="chongzhi"
        width="188px"
        height="118px"
        src="@/assets/images/user/chong.png"
        alt
      />
      <Recgarge></Recgarge>
    </div>
    <div>
      <!-- 登录/注册 -->
      <!-- 未登录 -->
      <div v-show="exist == 1" class="loginbtn" @click="loginBtn"></div>
      <!-- 已登录 -->
      <div
        v-show="exist == 2"
        class="loginbtn1"
        @click="$router.push({ name: 'personalCenter' })"
      >
        <div class="bean d-flex">
          <div class="money"></div>
          <div class="m-count f-13 color-white">{{ moneyCount }}</div>
        </div>
        <div class="jifen d-flex">
          <div class="zs"></div>
          <div class="zs-count f-13 color-white">{{ jifen }}</div>
        </div>
        <div class="portrait">
          <img
            v-if="portrait == null"
            src="@/assets/images/user/head.jpg"
            alt
          />
          <img v-else :src="portrait" alt />
        </div>
      </div>
      <div v-if="loginShow" class="d-flex biankuang">
        <div class="close" @click="close"></div>
        <!-- <div class="logo">
          <img src="@/assets/roll/logo.png" alt />
        </div>-->
        <!-- 切换 -->
        <div class="loginbox">
          <div
            class="login zt color-white"
            :class="iscolor ? 'glod' : ''"
            @click="changeLogin"
          >
            <!-- <img v-if="changePass == 1" src="@/assets/images/logo/login.png" alt />
            <img v-else src="@/assets/images/logo/loginn.png" alt />-->
            登录
          </div>
          <div
            class="reg zt color-white"
            :class="iscolor ? '' : 'glod'"
            @click="changeReg"
          >
            <!-- <img v-if="changePass==2" src="@/assets/images/logo/reg.png" alt />
            <img v-else src="@/assets/images/logo/regn.png" alt />-->
            注册
          </div>
        </div>
        <!-- 输入框 -->
        <div v-if="isLogin" class="loginIpt">
          <div class="ipt d-flex color-white">
            <div class="name">
              <input
                type="text"
                placeholder="请输入手机号"
                name="name"
                v-model="name"
                id
              />
            </div>
            <div class="pwd">
              <input
                type="password"
                placeholder="请输入密码"
                name="password"
                v-model="password"
                id
              />
            </div>
            <div class="promise d-flex">
              <div :class="sure ? 'bnt' : 'bnts'" @click="sures()"></div>
              我已满18岁并阅读和同意《用户协议》和《隐私政策》，承诺理性消费
            </div>
            <div class="btn color-white" @click="login" @keyup.enter="login">
              登录
            </div>
          </div>
          <!-- 忘记密码 -->
          <!-- <div class="forget f-13 color-white" @click="forgetpwd">忘记密码</div> -->
        </div>
        <div v-if="isReg" class="regIpt">
          <div class="ipt d-flex color-white">
            <div class="uname">
              <input
                type="text"
                placeholder="请输入用户名"
                name="name"
                id
                v-model="uname"
              />
            </div>
            <div class="name yzm">
              <input
                type="text"
                placeholder="请输入手机号"
                name="name"
                id
                v-model="phoneNum"
              />
              <div class="getcode color-green" v-show="jieliu" @click="yzm">
                <img src="@/assets/images/logo/yzm.png" alt />
              </div>
              <div class="getcode color-green" v-show="!jieliu">请稍后重试</div>
            </div>
            <div class="code">
              <input
                type="text"
                placeholder="请输入验证码"
                name="name"
                id
                v-model="code"
              />
            </div>
            <div class="pwd">
              <input
                type="password"
                placeholder="请输入密码"
                name="password"
                v-model="password"
                id
              />
            </div>
            <div class="yqm">
              <input
                type="text"
                placeholder="请输入邀请码(没有可以不填)"
                name="name"
                v-model="yqm"
                id
              />
            </div>
            <div class="promise d-flex">
              <div :class="sure ? 'bnt' : 'bnts'" @click="sures()"></div>
              我已满18岁并阅读和同意《用户协议》和《隐私政策》，承诺理性消费
            </div>
            <div
              class="regbtn color-white"
              @click="register"
              @keyup.enter="register"
            >
              注册
            </div>
          </div>
        </div>
        <!-- 忘记密码 -->
        <!-- <div v-if="forget" class="forgetBox">
          <div class="ipt d-flex color-white">
            <div class="name">
              <input
                type="text"
                placeholder="请输入手机号"
                v-model="phoneNum"
              />
            </div>
            <div class="yzm">
              <input type="text" placeholder="请输验证码" id v-model="code" />
              <div class="getcode color-green" v-show="jieliu" @click="yzm">
                <img src="@/assets/images/logo/yzm.png" alt />
              </div>
              <div class="getcode color-green" v-show="!jieliu">请稍后重试</div>
            </div>
            <div class="pwd">
              <input
                type="password"
                placeholder="请输入新密码"
                name="password"
                id
                v-model="newpass"
              />
            </div>
            <div
              class="btn color-white"
              @click="forgetBtn"
              @keyup.enter="forgetBtn"
            >
              找回密码
            </div>
          </div>
        </div> -->
        <!-- 人物图 -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLogin,
  getRegister,
  getcode,
  checkCode,
  changePass,
} from "../../../api/index";
import { setToken, getToken } from "../../../api/cookie";
import { getUserInfo } from "../../../api/index";
// import { mapState } from "vuex";
import Recgarge from "../../User/Recharge.vue";
export default {
  data() {
    return {
      sure: true,
      loginShow: false,
      isLogin: true,
      changePass: 1,
      isReg: false,
      forget: false,
      phone: true,
      phoneNum: "",
      uname: "",
      code: "",
      yqm: "",
      name: "",
      password: "",
      newpass: "",
      Login: false,
      moneyCount: 0,
      jifen: 0,
      exist: 0,
      token: getToken(),
      jieliu: true,
      portrait: "", //头像
      open: 0,
      iscolor: true,
    };
  },
  watch: {
    open() {
      getUserInfo().then((res) => {
        this.moneyCount = res.data.user.bean;
        this.jifen = res.data.user.storeBean;
      });
    },
  },
  components: {
    Recgarge,
  },
  mounted() {
    getUserInfo().then((res) => {
      this.moneyCount = res.data.user.bean;
      this.jifen = res.data.user.storeBean;
      this.portrait = res.data.user.portrait;
    });
    //开箱扣除金币和积分
    this.$bus.$on("openbox", (res) => {
      this.open += res;
    });
    // 未登录弹窗
    this.$bus.$on("unlogin", (data) => {
      this.loginShow = data;
    });
    // 修改密码
    this.$bus.$on("changePass", (pass) => {
      this.loginShow = pass;
      this.forget = true;
      this.isLogin = false;
    });
    this.portrait = this.$store.state.userAvater;
  },
  methods: {
    sures() {
      this.sure = !this.sure;
    },
    chongzhi() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.$bus.$emit("chongzhi", true);
      }
    },
    forgetBtn() {
      if (this.newpass == "" && this.phoneNum == "") {
        this.$message.error("请输入新密码或手机号");
        return;
      }
      let data = {
        phonenumber: this.phoneNum,
        checkword: this.code,
      };
      checkCode(data).then((res) => {
        if (res.data.code == 200) {
          let newdata = {
            phone: this.phoneNum,
            newPassword: this.newpass,
          };
          changePass(newdata).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message.success("修改成功，请登录！");
            } else {
              this.$message.error("错误，请重试！");
            }
          });
        } else {
          this.$message.error("验证码错误，请重试");
        }
      });
    },
    login() {
      if (this.sure == true) {
        this.$message.error("请点击同意用户协议");
      } else if (this.sure == false) {
        if (this.name == "" && this.password == "") {
          this.$message.error("请输入用户名或密码");
          return;
        }
        let data = {
          username: this.name,
          password: this.password,
        };
        getLogin(data)
          .then((res) => {
            let code = res.data.code;
            if (code == "200") {
              this.exist = 2;
              setToken(res.data.token);
              this.$message.success("登录成功！");
              this.close();
              //获取用户信息
              getUserInfo().then((res) => {
                this.moneyCount = res.data.user.bean;
                this.jifen = res.data.user.storeBean;
                this.portrait = res.data.user.portrait;
              });
              this.Login = true;
              this.$router.push("/home");
              //this.$router.go(0)
            } else if (code == "404") {
              this.$message.error("用户名或密码错误");
            } else if (code == "500") {
              this.$message.error("用户名或密码错误");
            }
          })
          .catch((err) => {
            throw new Error(err.message);
          });
      }
    },
    //发送验证码
    yzm() {
      if (this.uname == "" && this.phoneNum == "") {
        this.$message.error("请输入用户名或手机号");
        return;
      }
      let phone = this.phoneNum;
      getcode(phone).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg);
          this.jieliu = false;
          setTimeout(() => {
            this.jieliu = true;
          }, 60000);
        }
        if (res.data.code == 500) {
          this.$message.error("验证码错误，请重试");
        }
      });
    },
    register() {
      if (this.sure == true) {
        this.$message.error("请点击同意用户协议");
      } else if (this.sure == false) {
        if (this.uname == "" && this.phoneNum == "") {
          this.$message.error("请输入用户名或手机号");
          return;
        }
        let data = {
          phonenumber: this.phoneNum,
          checkword: this.code,
        };
        checkCode(data).then((res) => {
          if (res.data.code == 200) {
            let data = {
              username: this.uname,
              password: this.password,
              phone: this.phoneNum,
            };
            getRegister(data).then((res) => {
              if (res.data.code == 200) {
                this.$message.success("注册成功！");
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    loginBtn() {
      if (!this.loginShow) {
        this.loginShow = true;
      } else {
        this.loginShow = false;
      }
    },
    close() {
      this.loginShow = false;
      this.name = "";
      this.password = "";
    },
    changeLogin() {
      if (this.iscolor) {
        this.iscolor = false;
      } else {
        this.iscolor = true;
      }
      if (!this.isLogin) {
        this.isLogin = true;
        this.isReg = false;
        this.forget = false;
        this.changePass = 1;
      }
    },
    changeReg() {
      if (this.iscolor) {
        this.iscolor = false;
      } else {
        this.iscolor = true;
      }
      if (!this.isReg) {
        this.isLogin = false;
        this.isReg = true;
        this.forget = false;
        this.changePass = 2;
      }
    },
    forgetpwd() {
      if (!this.forget) {
        this.isLogin = false;
        this.isReg = false;
        this.forget = true;
        this.changePass = 3;
      }
    },
  },
  created() {
    let token = getToken();
    if (token) {
      this.exist = 2;
    } else {
      this.exist = 1;
    }
  },
};
</script>

<style scoped lang="scss">
.glod {
  color: gold;
}
.loginbtn {
  width: 160px;
  height: 100px;
  background: url("~@/assets/images/home/login.png");
  background-size: 100% 100%;
}
.loginbtn1 {
  position: relative;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #eee;
  font-weight: none;
  font-size: 20px;
  margin-right: 20px;
  .portrait {
    width: 50px;
    height: 50px;
    border: 1px solid rgb(236, 248, 1);
    border-radius: 50%;
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    top: 2px;
    right: 11px;
  }
  .portrait img {
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    top: 0px;
    right: 0px;
  }
}
.bean {
  margin-right: 10px;
  height: 20px;
  line-height: 20px;
  .money {
    width: 15px;
    height: 15px;
    background: url("~@/assets/images/pk/wmoney.png") no-repeat;
    background-size: 100% 100%;
    margin-right: 5px;
  }
}

.jifen {
  margin-right: 10px;
  height: 20px;
  line-height: 20px;
  .zs {
    width: 15px;
    height: 15px;
    background: url("~@/assets/images/pk/zs.png") no-repeat;
    background-size: 100% 100%;
    margin-right: 5px;
  }
}

.biankuang {
  width: 550px;
  height: auto;
  top: 16%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 10px);
  z-index: 999;
  background: url("~@/assets/images/logo/border.png") no-repeat;
  background-size: 100% 100%;
  padding: 35px 120px;
  box-sizing: border-box;

  .close {
    position: absolute;
    top: 58px;
    right: 40px;
    width: 35px;
    height: 35px;
    background: url("~@/assets/images/logo/close.png") no-repeat;
    background-size: 100% 100%;
  }
  .logo {
    width: 80%;
    margin: 60px auto 0 auto;
    img {
      width: 100%;
    }
  }

  .loginbox {
    position: absolute;
    top: 0px;
  }
  .forgetBox {
    margin-top: 80px;
  }
  .regIpt {
    margin-top: 90px;
  }
  .login {
    font-size: 26px;
    position: absolute;
    top: 70px;
    right: -114px;
    img {
      width: 80px;
    }
  }

  .reg {
    font-size: 26px;

    position: absolute;
    top: 70px;
    right: -214px;
    img {
      width: 80px;
    }
  }

  .ipt {
    width: 300px;
    flex-wrap: wrap;
    input::placeholder {
      color: #ccc;
    }

    input {
      width: 100%;
      height: 100%;
      outline: none;
      color: white;
      border: 1px solid #0197f7;
      font-size: 18px;
      text-align: left;
      padding-left: 5px;
      margin-left: 5px;
      background-color: rgba(0, 0, 0, 0);
      // background-image: linear-gradient(90deg, #6938b7, #9c02b8);
    }

    .name,
    .pwd,
    .btn,
    .uname,
    .code,
    .yqm,
    .yzm,
    .regbtn {
      width: 80%;
      height: 35px;
      margin: 10px auto;
    }

    .btn,
    .regbtn {
      text-align: center;
      line-height: 35px;
      background-color: #0752a5;
      font-size: 18px;
      border-radius: 10px;
    }
  }

  .forget {
    width: 80px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    float: right;
    background: none;
    border-radius: 15px;
    border: 2px solid #0197f7;
  }

  .text {
    width: 63%;
    color: #71ed00;
    font-size: 16px;
    margin: 60px auto;
  }

  .yzm {
    position: relative;

    .getcode {
      position: absolute;
      top: 15px;
      right: 10px;
      font-size: 12px;
    }
  }

  .loginIpt {
    border-top: 1px solid transparent;
    margin-top: 90px;
    flex: none;
  }
}
.promise {
  width: 270px;
  margin: auto;
  font-size: 12px;
  color: #ccc;
  .bnt {
    flex: none;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    margin-top: 5px;
    border: 1px solid yellow;
  }
  .bnts {
    flex: none;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    margin-top: 5px;
    background-color: yellow;
    border: 1px solid yellow;
  }
}
@media (max-width: 550px) {
  .chong {
    display: none;
  }
  .biankuang {
    width: 100%;
    height: auto;
    top: 16%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 10px);
    z-index: 999;
    background: url("~@/assets/images/logo/border.png") no-repeat;
    background-size: 100% 100%;
    padding: 30px 36px;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 58px;
      right: 40px;
      width: 30px;
      height: 30px;
      background: url("~@/assets/images/logo/close.png") no-repeat;
      background-size: 100% 100%;
    }
    .logo {
      width: 80%;
      margin: 60px auto 0 auto;

      img {
        width: 100%;
      }
    }

    .loginbox {
      position: absolute;
      top: -10px;
      left: 0;
    }
    .regIpt {
      margin-top: 90px;
    }
    .login {
      font-size: 26px;

      position: absolute;
      top: 80px;
      right: -114px;
      img {
        width: 50px;
      }
    }

    .reg {
      position: absolute;
      font-size: 26px;
      top: 80px;
      right: -214px;
      img {
        width: 50px;
      }
    }

    .ipt {
      width: 300px;
      flex-wrap: wrap;
      input::placeholder {
        color: #ccc;
      }

      input {
        width: 100%;
        height: 100%;
        outline: none;
        color: white;
        border: 1px solid #0197f7;
        font-size: 18px;
        text-align: left;
        padding-left: 5px;
        margin-left: 5px;
        background-color: rgba(0, 0, 0, 0);
        // background-image: linear-gradient(90deg, #6938b7, #9c02b8);
      }

      .name,
      .pwd,
      .btn,
      .uname,
      .code,
      .yqm,
      .yzm,
      .regbtn {
        width: 80%;
        height: 35px;
        margin: 10px auto;
      }

      .btn,
      .regbtn {
        text-align: center;
        line-height: 35px;
        background-color: #0752a5;
        font-size: 18px;
        border-radius: 10px;
      }
    }

    .forget {
      width: 80px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      float: right;
      background: none;
      border-radius: 15px;
      border: 2px solid #0c3799;
    }

    .text {
      width: 63%;
      color: #71ed00;
      font-size: 16px;
      margin: 60px auto;
    }

    .yzm {
      position: relative;

      .getcode {
        position: absolute;
        top: 15px;
        right: 10px;
        font-size: 12px;
      }
    }

    .loginIpt {
      border-top: 1px solid transparent;
      margin-top: 90px;
      flex: none;
    }
  }
}
</style>
