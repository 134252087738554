<template>
  <div class="home" v-show="ishow">
    <div class="goget">
      <img
        class="leftimg"
        src="@/assets/images/home/left.png"
        alt
        v-show="cur == true"
        @click="close"
      />
    </div>
    <div class="aside" :class="cur ? 'aside-middle' : 'aside-left'">
      <div>
        <img
          class="rightimg"
          src="@/assets/images/home/right.png"
          alt
          v-show="cur == false"
          @click="open"
        />
      </div>
      <transition name="el-fade-in">
        <div class="aside-right" v-show="cur == false">
          <div class="aside-item qq">
            <img src="@/assets/images/home/qq.png" alt />
            官方Q群
          </div>

          <img class="aside-img img-hover" src="@/assets/images/home/nav.png" alt />

          <div class="aside-item" @click="kefu">
            <img class="aside-img" src="@/assets/images/home/kefu.png" alt />
            客服中心
          </div>
          <div class="aside-item" @click="setTop">
            <img class="aside-img" src="@/assets/images/home/up.png" alt />
            返回顶部
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cur: true,
      redpacket: false,
      cdkdialog: false,
      ishow: true
    };
  },
  methods: {
    kefu() {
      window.open(
        "https://www5.53kf.com/webCompany.php?kf_sign=TMyMjMTY5OM3NTEyMjM1Mjc5MDg0MDAwNzI5MDIxODE=&arg=10902181&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Flocalhost%3A8080%2FHome&keyword=http%3A%2F%2Flocalhost%3A8080%2FHome&tfrom=1&tpl=crystal_blue&uid=1a069354c6a4ec3ba9c63c8cc784e865&is_group=&is_group=&timeStamp=1699322378817&ucust_id="
      );
    },
    open() {
      this.cur = true;
    },
    close() {
      this.cur = false;
    },
    // 置顶
    setTop() {
      document.documentElement.scrollTop = 0;
    },
    //红包
    openRed() {
      this.cdkdialog = false;
      this.redpacket = true;
    },
    closeRed() {
      this.redpacket = false;
    },
    openCdk() {
      this.redpacket = false;
      this.cdkdialog = true;
    }
  },
  created() {
    this.$bus.$on("unshow", show => {
      this.ishow = show;
    });
    this.$bus.$on("show", show => {
      this.ishow = show;
    });
  }
};
</script>

<style lang="scss" scoped>
.aside-left {
  // display: block;
  right: 0px;
  transition: 0.3s;
}
.aside-middle {
  // display: none;
  right: -57px;
  transition: 0.3s;

  img {
    width: 35px;
    height: 35px;
  }
}
.goget {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 40%;
  right: 4px;
  z-index: 701;
}
.aside {
  position: fixed;
  top: 38%;
  // right: 4px;
  z-index: 700;
  box-sizing: border-box;
  width: 80px;
  height: 292px;
  background: url("~@/assets/images/home/sidebg.png") no-repeat;
  background-size: 100% 100%;

  .aside-right {
    position: absolute;
    right: 0px;
    top: 28px;
    color: #ebbe68;
    // background: url("../assets/images/home/cbl.png");
    background-size: 100% 100%;
    .aside-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      font-size: 12px;
      .aside-img {
        width: 40px;
        height: 40px;
      }

      span {
        font-size: 12px;
      }
    }

    .qq:hover + .img-hover {
      display: block;
    }

    .img-hover {
      width: 180px;
      height: 180px;
      display: none;
      position: absolute;
      top: 60px;
      right: 75px;
    }
  }
}
.leftimg {
  width: 17px;
  height: 31px;
  position: absolute;
  z-index: 701;
  top: 116px;
  left: 35px;
}
.rightimg {
  width: 17px;
  height: 31px;
  position: absolute;
  z-index: 701;
  top: 130px;
  left: 5px;
}
</style>