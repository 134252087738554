import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "swiper/swiper.min.css"
import common from './assets/css/common.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Swiper from 'swiper';
import "@/assets/font/iconfont.css"

import vueSeamlessScroll from 'vue-seamless-scroll'
Vue.use(vueSeamlessScroll)

Vue.use(ElementUI);
Vue.config.productionTip = false

import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
// let ws = new WebSocket(
//   "ws://192.168.3.25:8080/api/battleRoyale/websocket/104/1"
// );

 new Vue({
  router,
  store,
  common,
  Swiper,
  beforeCreate(){
    Vue.prototype.$bus = this
    // Vue.prototype.$ws=ws
  },
  render: h => h(App)
}).$mount('#app')

